const ANNOUNCEMENT_PRICE_NAME = {
  price: 'Номинальная цена',
  purchase_price: 'Закупочная цена',
  total_price: 'Цена',
};

const ANNOUNCEMENT_PRICE_TYPE = {
  PRICE: 'price',
  PURCHASE_PRICE: 'purchase_price',
  TOTAL_PRICE: 'total_price',
};

const ANNOUNCEMENT_HISTORY_TYPE = {
  CREATE: 1,
  META: 2,
  PRICE_CHANGED: 3,
  CHANGE_STATUS: 4,
  TICKET_SELL: 5,
  TICKET_SOLD: 6,
  PROVIDER_CHANGE: 7,
  TYPE_CHANGE: 8,
  EXPANSION_ADD: 9,
  EXPANSION_DELETE: 10,
  REFUND: 11,
  DELETE_TICKETS: 12,
  ADD_TICKETS: 13,
  TRANSFER_TO: 14,
  TRANSFER_FROM: 15,
};

export enum AnnouncementsVisibleScope {
  SEATS = 1,
  ROW = 2,
  SECTOR = 3,
  CATEGORY = 4,
}

export const AnnouncementsVisibleScopeNames: { [key: number]: string } = {
  [AnnouncementsVisibleScope.SEATS]: 'Места',
  [AnnouncementsVisibleScope.ROW]: 'Ряд',
  [AnnouncementsVisibleScope.SECTOR]: 'Сектор',
  [AnnouncementsVisibleScope.CATEGORY]: 'Категория',
};

export const ANNOUNCEMENTS_VISIBLE_SCOPE_TYPE_OPTIONS = [
  {
    name: 'Места',
    value: AnnouncementsVisibleScope.SEATS,
    id: AnnouncementsVisibleScope.SEATS,
  },
  { name: 'Ряд', value: AnnouncementsVisibleScope.ROW, id: AnnouncementsVisibleScope.ROW },
  {
    name: 'Сектор',
    value: AnnouncementsVisibleScope.SECTOR,
    id: AnnouncementsVisibleScope.SECTOR,
  },
];

export const STANDING_ANNOUNCEMENTS_VISIBLE_SCOPE_TYPE_OPTIONS = [
  {
    name: 'Места',
    value: AnnouncementsVisibleScope.SEATS,
    id: AnnouncementsVisibleScope.SEATS,
  },
  {
    name: 'Сектор',
    value: AnnouncementsVisibleScope.SECTOR,
    id: AnnouncementsVisibleScope.SECTOR,
  },
];

export { ANNOUNCEMENT_PRICE_NAME, ANNOUNCEMENT_PRICE_TYPE, ANNOUNCEMENT_HISTORY_TYPE };
