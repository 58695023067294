import config from 'config/config';
import get from 'lodash/get';
import head from 'lodash/head';
import sortBy from 'lodash/sortBy';
import Router from 'next/router';
import queryString from 'query-string';
import { i18n } from '../../i18n';
import { LANGUAGES } from '../constants/LANGUAGES';

export default {
  root: '/',
  login: '/login',
  registration: '/registration',
  forgot: '/forgot',
  newPassword: '/new-password',

  referenceCountries: '/reference/countries',
  referenceCurrency: '/reference/currency',
  referenceLocations: '/reference/locations',
  referencePlaces: '/reference/places',
  referenceHalls: '/reference/halls',
  referenceEventCategories: '/reference/event-categories',
  referenceExpansinons: '/reference/expansions',
  referenceTags: '/reference/tags',
  referencePersons: '/reference/persons',
  referenceSubdomains: '/reference/subdomains',
  referenceActual: '/reference/actual',
  reference: '/reference',
  referenceQnA: '/reference/qna',

  marketEvents: '/market/events',
  marketPersons: '/market/persons',
  marketPlaces: '/market/places',

  profile: '/profile',
  profileSecurity: '/profile/security',

  halls: '/halls',
  hall: (id: string | number) => `/halls/${id}`,

  hallLayout: (id: string | number) => `${config.EDITOR_DOMAIN}/editor/${id}`,

  widget: (id: string | number) => `/widget/${id}`,

  purchases: '/purchases',
  sales: '/sales',
  sale: (id: string | number) => `/sales?id=${id}`,
  preorders: '/preorders',
  invoices: '/invoices',
  announcements: '/announcements',
  announcementsV2: '/announcements-v2',
  announcement: (id: string | number) => `/announcements/${id}`,

  myCompany: '/company',
  myCompanyMembers: '/company/members',
  myCompanyProviders: '/company/providers',
  myCompanyPayments: '/company/payments',
  myCompanyRoles: '/company/roles',
  myCompanyCreate: '/company/create',
  myCompanyEmailConfig: '/company/email-config',
  myCompanyLegalInfo: '/company/legal-info',
  myCompanyAtolConfig: '/company/atol-config',
  myCompanyPhones: '/company/phones',
  myCompanyRoundingMarkupMarket: '/company/rounding-markup-market',
  myCompanyRoundingMarkupLanding: '/company/rounding-markup-landing',

  notifications: '/notifications',

  pool: '/pool',
  poolDetail: (id: string | number) => `/pool/${id}`,

  events: '/events',
  bundlesActive: '/bundles/active',
  bundlesArchive: '/bundles/archive',

  parsersEvents: '/parsers',
  parsersViewers: '/parsers/viewers',
  parsersVenues: '/parsers/venues',
  parsersCategories: '/parsers/categories',

  membersUsers: '/members',
  membersCompanies: '/members/companies',

  landingsNews: '/landings/news',
  landingsSites: '/landings/sites',
  landingsPersonalities: '/landings/persons',
  landingsEvents: '/landings/events',
  landingsPlaygrounds: '/landings/places',
  landingsTags: '/landings/tags',
  landingsSEO: '/landings/seo',
  landingsTemplatesText: '/landings/templates-text',
  landingsCustom: '/landings/custom',
  landingsTemplateSets: '/landings/template-sets',
  landingsRedirects: '/landings/redirects',
  landingsQueue: '/landings/queue',

  widgets: '/widgets',
  widgetsPatters: '/widgets/patterns',

  markup: '/markup',
  filters: '/filters',

  reportsDynamic: '/reports/dynamic',
  reportsSales: '/reports/sales',
  reportsOrders: '/reports/orders',
  reportsRefunds: '/reports/refunds',
  reportsDelivery: '/reports/delivery',
  reportsPurchases: '/reports/purchases',
  reportsExpired: '/reports/expired',
  reportsEvent: '/reports/event',
  reportsEventFragment: '/reports/event/fragment',
  reportsRejects: '/reports/rejects',
  reportsPurchasesB2C: '/reports/purchases/b2c',
  reportsPrincipals: '/reports/principals',

  pbReportsDynamic: '/pbreports/dynamic',
  pbReportsSales: '/pbreports/sales',
  pbReportsOrders: '/pbreports/orders',
  pbReportsRefunds: '/pbreports/refunds',
  pbReportsDelivery: '/pbreports/delivery',
  pbReportsPurchases: '/pbreports/purchases',
  pbReportsExpired: '/pbreports/expired',
  pbReportsEvent: '/pbreports/event',
  pbReportsEventFragment: '/pbreports/event/fragment',
  pbReportsRejects: '/pbreports/rejects',
  pbReportsPurchasesB2C: '/pbreports/purchases/b2c',
  pbReportsPrincipals: '/pbreports/principals',

  searchInvoices: '/search/invoices',
  searchOrders: '/search/orders',
  searchPool: '/search/pool',
  searchAnnouncements: '/search/announcements',
  searchEvents: '/search/events',

  clients: '/clients',

  seoMarketplaceMain: '/seo-marketplace/main',
  seoMarketplaceEvents: '/seo-marketplace/events',
};

export const routesPatterns = {
  announcements: '/announcements/[event]',
  pool: '/pool/[event]',
  reference: '/reference/[name]',
  bundles: '/bundles/[name]',
  market: '/market/[name]',
};

export interface Query {
  [key: string]: string | number | undefined;
}

export function getCurrentRouteWithMergedQuery(query: Query) {
  const mergedQuery = {
    ...queryString.parse(window.location.search),
    ...query,
  };
  return `${window.location.pathname}?${queryString.stringify(mergedQuery)}`;
}

export function getCurrentRouteWithNewQuery(query: Query) {
  return `${window.location.pathname}?${queryString.stringify(query)}`;
}

export function updateQuery(query: Query) {
  Router.push(
    {
      pathname: Router.router?.pathname,
      query: {
        ...Router.router?.query,
        ...query,
      },
    },
    getCurrentRouteWithMergedQuery(query),
    { shallow: true },
  ).catch((err) => new Error(err));
}

export function replaceQuery(query: Query) {
  Router.push(
    {
      pathname: Router.router?.pathname,
      query,
    },
    getCurrentRouteWithNewQuery(query),
    { shallow: true },
  ).catch((err) => new Error(err));
}

export function cleanURL(url: string) {
  return url.split('?')[0];
}

export function getRouteWithChildren({
  data,
  route,
}: {
  data: any;
  route: (value: string | number) => string;
}) {
  let path = route(data.id);
  const dataChildren = get(data, 'children.length');

  if (dataChildren && dataChildren > 0) {
    path = route(head(sortBy(data.children, 'date_start')).id);
  }

  return path;
}

interface RouteProps {
  route: string;
  lng: string;
  create?: boolean;
}

export function getTranslationRoute({ route, lng = i18n!.language, create }: RouteProps) {
  return lng !== LANGUAGES.RU ? `${route}/translation${create ? '' : `/${lng}`}` : route;
}
