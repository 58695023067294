import config from 'config/config';

export const searchSources = {
  invoices: `${config.TICKETS_API_URL}/admin/v1/invoices/search`,
  orders: 'search/orders',
  events: 'search/events',
  announcementEvents: 'search/announcement-events',
  poolEvents: 'search/pool-events',
  counts: 'search/counts',
};
