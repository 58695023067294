import { AxiosError } from 'axios';
import { createEffect } from 'effector';
import omit from 'lodash/omit';
import React from 'react';
import config from 'config/config';
import COOKIES from 'shared/constants/COOKIES';
import { PagePaginationQuery } from 'shared/factory';
import { getCookie } from 'shared/lib/cookie';
import api from 'shared/services/api';
import announcementsSource from 'shared/sources/announcements';
import expansionSources from 'shared/sources/expansion';
// eslint-disable-next-line @conarti/feature-sliced/layers-slices, boundaries/element-types
import { companySources } from '../company';
// eslint-disable-next-line @conarti/feature-sliced/layers-slices, boundaries/element-types
import { eventsSources } from '../events';
// eslint-disable-next-line @conarti/feature-sliced/layers-slices, boundaries/element-types
import { IPaginatedHistory } from '../history';
import {
  AnnouncementsData,
  IAnnouncementPageData,
  IAnnouncementUpdateRequestPrams,
  ICreateAnnouncementsParams,
  ICurrency,
  IExpansionParams,
  IProviderParams,
} from './types';

const companyId = getCookie(COOKIES.COMPANY);
const currencySource = 'reference/currency';

export const announcementsSources = {
  announcements: (id: number) => `${config.TICKETS_API_URL}/admin/v1/events/${id}/announcements`,
  announcementsCounts: (id: number) => `/event/count/${id}/announcements`,
  detail: (id: React.Key | number) => `${config.TICKETS_API_URL}/admin/v1/announcements/${id}`,
  raw: (id: number) => `${config.TICKETS_API_URL}/admin/v1/announcements/${id}/raw`,
  addTickets: (id: number) => `${config.TICKETS_API_URL}/admin/v1/announcements/${id}/add-tickets`,
  deleteTickets: (id: number) => `${config.TICKETS_API_URL}/admin/v1/announcements/${id}/delete-tickets`,
  refundTickets: (id: number) => `${config.TICKETS_API_URL}/admin/v1/announcements/${id}/refund-tickets`,
  separate: (id: number) => `${config.TICKETS_API_URL}/admin/v1/announcements/${id}/separate`,
  updateStatus: `${config.TICKETS_API_URL}/admin/v1/announcements/update-status`,
  updatePrice: `${config.TICKETS_API_URL}/admin/v1/announcements/update-price`,
  massDelete: `${config.TICKETS_API_URL}/admin/v1/announcements/delete`,
  history: (id: React.Key | number) => `${config.TICKETS_API_URL}/admin/v1/announcements/${id}/history`,
};

export const fetchAnnouncementsFx = createEffect<PagePaginationQuery, AnnouncementsData, AxiosError>(
  async (query) => {
    const { outdated, ...rest } = query;
    const response = await api.get(eventsSources.root, {
      params: {
        with_announcements: true,
        date_type: outdated ? 'all' : 'active',
        company_id: companyId,
        ...rest,
      },
    });

    return response.data;
  },
);

export const fetchAnnouncementsDetailFx = createEffect<
  PagePaginationQuery,
  IAnnouncementPageData,
  AxiosError
>(async ({ event, ...rest }) => {
  return await Promise.all([
    api
      .get(announcementsSources.announcements(Number(event)), {
        params: rest,
      })
      .then((response) => response.data),
    api
      .get(announcementsSources.announcementsCounts(Number(event)), {
        params: omit(rest, ['page', 'limit']),
      })
      .then((response) => response.data),
  ]).then(([data, counts]) => ({ data, counts }));
});

export const fetchAnnouncementRaw = async (id: number) => api.get(announcementsSources.raw(id));

export const announcementsTicketsAdd = async (id: number, data: any) =>
  await api.post(announcementsSources.addTickets(id), data);

export const announcementsTicketsDelete = async (id: number, data: any) =>
  await api.delete(announcementsSources.deleteTickets(id), { data });

export const announcementsTicketsSeparate = async (id: number, data: any) =>
  await api.post(announcementsSources.separate(id), data);

export const announcementsTicketsRefund = async (id: number, data: any) =>
  await api.delete(announcementsSources.refundTickets(id), { data });

export const announcementsUpdateStatus = async (data: {
  status: number;
  announcements: number[] | React.Key[];
}) => await api.post(announcementsSources.updateStatus, data);

export const announcementsUpdatePrice = async (data: {
  price: number;
  announcements: number[] | React.Key[];
}) => await api.post(announcementsSources.updatePrice, data);

export const announcementsUpdate = async ({
  id,
  data,
}: {
  id: React.Key | number;
  data: Partial<IAnnouncementUpdateRequestPrams>;
}) => await api.patch(announcementsSources.detail(id), data);

export const announcementsUpdateStatusFx = createEffect(announcementsUpdateStatus);
export const announcementsUpdatePriceFx = createEffect(announcementsUpdatePrice);
export const announcementsUpdateFx = createEffect(announcementsUpdate);

export const fetchCurrencyFx = createEffect<never, ICurrency[], AxiosError>(async () => {
  const response = await api.get(currencySource);
  const { results }: { results: ICurrency[] } = response.data.data;
  return results;
});

export const createAnnouncementsFx = createEffect<ICreateAnnouncementsParams[], void, AxiosError>(
  async (data) => {
    await api.post(announcementsSource.bulkCreate, { announcements: data });
  },
);

export const fetchProvidersFx = createEffect<never, IProviderParams[], AxiosError>(async () => {
  const response = await api.get(companySources.providers);
  const { results }: { results: IProviderParams[] } = response.data.data;

  return results;
});

export const fetchExpansionFx = createEffect<never, IExpansionParams[], AxiosError>(async () => {
  const response = await api.get(expansionSources.root);
  const { results }: { results: IExpansionParams[] } = response.data.data;

  return results;
});

export const deleteAnnouncementFx = createEffect<number, any, AxiosError>(async (id) => {
  const response = await api.delete(announcementsSources.detail(id));
  return response;
});

export const massDeleteAnnouncements = async (data: { announcements: number[] | React.Key[] }) =>
  await api.delete(announcementsSources.massDelete, {
    data,
  });

export const fetchAnnouncementHistoryFx = createEffect<
  { id: React.Key | number; params?: { [key: string]: any } },
  IPaginatedHistory,
  AxiosError
>(async ({ id, params }) => {
  const response = await api.get(announcementsSources.history(id), { params });
  return response.data;
});

export interface ResponseDeleteAnnouncementsError extends Error {
  response: {
    data: {
      detail: {
        errors: number[];
      };
    };
  };
}

export const deleteAnnouncementsFx = createEffect<
  { announcements: number[] | React.Key[] },
  any,
  ResponseDeleteAnnouncementsError
>(massDeleteAnnouncements);
