export default {
  POOL: 'POOL',
  POOL_DETAIL: 'POOL_DETAIL',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  ANNOUNCEMENT_DETAIL: 'ANNOUNCEMENT_DETAIL',
  MY_PURCHASES: 'MY_PURCHASES',
  MY_SALES: 'MY_SALES',
  PREORDERS: 'PREORDERS',
  EVENTS: 'EVENTS',
  BUNDLES_ACTIVE: 'BUNDLES_ACTIVE',
  BUNDLES_ARCHIVE: 'BUNDLES_ARCHIVE',
  WIDGETS: 'WIDGETS',
  WIDGETS_PATTERNS: 'WIDGETS_PATTERNS',
  INVOICES: 'INVOICES',

  PARSERS_EVENTS: 'PARSERS_EVENTS',
  PARSERS_VIEWERS: 'PARSERS_VIEWERS',
  PARSERS_VENUES: 'PARSERS_VENUES',
  PARSERS_CATEGORIES: 'PARSERS_CATEGORIES',

  REPORTS_SALES: 'REPORTS_SALES',
  REPORTS_ORDERS: 'REPORTS_ORDERS',
  REPORTS_REFUNDS: 'REPORTS_REFUNDS',
  REPORTS_PURCHASES: 'REPORTS_PURCHASES',
  REPORTS_DELIVERY: 'REPORTS_DELIVERY',
  REPORTS_EXPIRED: 'REPORTS_EXPIRED',
  REPORTS_EVENT: 'REPORTS_EVENT',
  REPORTS_EVENT_FRAGMENT: 'REPORTS_EVENT_FRAGMENT',
  REPORTS_REJECTS: 'REPORTS_REJECTS',
  REPORTS_PURCHASES_B2C: 'REPORTS_PURCHASES_B2C',
  REPORTS_PRINCIPALS: 'REPORTS_PRINCIPALS',

  SEARCH_POOL: 'SEARCH_POOL',
  SEARCH_ORDERS: 'SEARCH_ORDERS',
  SEARCH_ANNOUNCEMENTS: 'SEARCH_ANNOUNCEMENTS',
  SEARCH_EVENTS: 'SEARCH_EVENTS',
  SEARCH_INVOICES: 'SEARCH_INVOICES',

  COMPANY_MEMBERS: 'COMPANY_MEMBERS',
  COMPANY_PROVIDES: 'COMPANY_PROVIDES',
  COMPANY_PAYMENT_METHODS: 'COMPANY_PAYMENT_METHODS',
  COMPANY_ROLES: 'COMPANY_ROLES',
  COMPANY_PHONES: 'COMPANY_PHONES',

  LANDING_EVENTS: 'LANDING_EVENTS',
  LANDING_NEWS: 'LANDING_NEWS',
  LANDING_SITES: 'LANDING_SITES',
  LANDING_PERSONALITIES: 'LANDING_PERSONALITIES',
  LANDING_PLAYGROUNDS: 'LANDING_PLAYGROUNDS',
  LANDING_TAGS: 'LANDING_TAGS',
  LANDING_SEO: 'LANDING_SEO',
  LANDING_TEMPLATE: 'LANDING_TEMPLATES',
  LANDING_CUSTOM: 'LANDING_CUSTOM',
  LANDING_TEMPLATE_SETS: 'LANDING_TEMPLATE_SETS',
  LANDING_REDIRECTS: 'LANDING_REDIRECTS',
  LANDING_QUEUE: 'LANDING_QUEUE',

  MEMBERS_USERS: 'MEMBERS_USERS',
  MEMBERS_COMPANIES: 'MEMBERS_COMPANIES',

  REFERENCE_PLACES: 'REFERENCE_PLACES',
  REFERENCE_HALLS: 'REFERENCE_HALLS',
  REFERENCE_EVENT_CATEGORIES: 'REFERENCE_EVENT_CATEGORIES',
  REFERENCE_COUNTRIES: 'REFERENCE_COUNTRIES',
  REFERENCE_PERSONS: 'REFERENCE_PERSONS',
  REFERENCE_EXPANSION: 'REFERENCE_EXPANSION',
  REFERENCE_TAGS: 'REFERENCE_TAGS',
  REFERENCE_LOCATIONS: 'REFERENCE_LOCATIONS',

  MARKET_PLACES: 'MARKET_PLACES',
  MARKET_PERSONS: 'MARKET_PERSONS',

  NOTIFICATIONS: 'NOTIFICATIONS',

  CLIENTS: 'CLIENTS',

  MARKETPLACE_SEO_EVENTS: 'MARKETPLACE_SEO_EVENTS',
};
