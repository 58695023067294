import { css } from 'styled-components';

const scrollbar = css`
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-right: solid 2px transparent;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px #e1e1e1;
    border-right: solid 2px transparent;
    box-sizing: border-box;
  }
`;

const layoutScrollbar = css`
  &::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }
  &::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    background-color: #c5c5c5;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const darkScrollbar = css`
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-right: solid 2px transparent;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px #525252;
    border-right: solid 2px transparent;
    box-sizing: border-box;
  }
`;

const horizontalDarkScrollbar = css`
  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-right: solid 2px transparent;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px #525252;
    border-right: solid 2px transparent;
    box-sizing: border-box;
  }
`;

export { scrollbar, darkScrollbar, horizontalDarkScrollbar, layoutScrollbar };
