const ANNOUNCEMENTS_TYPES_ENUM = {
  PREORDER: 0,
  IN_STOCK: 1,
};

const ANNOUNCEMENTS_TYPES = {
  [ANNOUNCEMENTS_TYPES_ENUM.PREORDER]: 'Предзаказ',
  [ANNOUNCEMENTS_TYPES_ENUM.IN_STOCK]: 'В наличии',
};

const ANNOUNCEMENT_TYPES_OPTIONS = [
  {
    id: ANNOUNCEMENTS_TYPES_ENUM.PREORDER,
    value: ANNOUNCEMENTS_TYPES_ENUM.PREORDER,
    label: ANNOUNCEMENTS_TYPES[ANNOUNCEMENTS_TYPES_ENUM.PREORDER],
  },
  {
    id: ANNOUNCEMENTS_TYPES_ENUM.IN_STOCK,
    value: ANNOUNCEMENTS_TYPES_ENUM.IN_STOCK,
    label: ANNOUNCEMENTS_TYPES[ANNOUNCEMENTS_TYPES_ENUM.IN_STOCK],
  },
];

export { ANNOUNCEMENTS_TYPES, ANNOUNCEMENT_TYPES_OPTIONS, ANNOUNCEMENTS_TYPES_ENUM };
